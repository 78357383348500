<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flexspabet"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <div style="margin-bottom: 10px">
          <el-button @click="showEdit=true" size="small" v-if="permission.includes('sys:vmpackage:add')"
                     class="ele-btn-icon addbtn ">添加套餐
          </el-button>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row stripe class="my-dialog-name">
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" width="200px" label="序号" align="center"
                           show-overflow-tooltip/>
          <el-table-column prop="title" label="套餐类型" show-overflow-tooltip />
          <el-table-column prop="price" label="单价(元/分钟)包含录音" show-overflow-tooltip />
          <el-table-column prop="month_price" label="月租/元" show-overflow-tooltip /><div>按照虚拟号数量扣除对应月租</div>   
          <el-table-column prop="year_price" label="总金额/元" show-overflow-tooltip />
          <el-table-column label="操作" align="center" :resizable="false">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
                       v-if="permission.includes('sys:vmpackage:edit')">编辑
              </el-link>
              <el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
                       v-if="permission.includes('sys:vmpackage:delete')">删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 添加弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑虚拟号套餐类型':'添加虚拟号套餐类型'" :visible.sync="showEdit" @closed="editForm={}"
               :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="200px">
          <el-form-item label="套餐类型：" prop="title">
            <el-input type="text" v-model="editForm.title" aria-placeholder="请输入套餐类型" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="单价(元/分钟)包含录音：" prop="price">
            <el-input type="text" v-model="editForm.price" aria-placeholder="请输入单价金额" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="月租/元：" prop="month_price">
            <el-input type="text" v-model="editForm.month_price" aria-placeholder="请输入月租金额" class="input216">
            </el-input>
                 <span style="color:red">（按照虚拟号数量扣除对应月租）</span> 
          </el-form-item>
          <el-form-item label="总金额/元：" prop="year_price">
            <el-input type="text" v-model="editForm.year_price" aria-placeholder="请输入总金额" class="input216">
            </el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'SysCountyCircle',
  components: {},
  data () {
    return {
      table: {
        url: '/VmPackage/index',
        where: {}
      }, // 表格配置
      provArr: [],
      cityArr: [],
      districtArr: [],
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {}, // 表单数据
      userInfo: {},
      editRules: { // 表单验证规则
        title: [{
          required: true,
          message: '请输入套餐类型',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入单价金额',
          trigger: 'blur'
        }],
        month_price: [{
          required: true,
          message: '请输入月租金额',
          trigger: 'blur'
        }],
        year_price: [{
          required: true,
          message: '请输入总金额',
          trigger: 'blur'
        }],
      },
      editp: false,
      editc: false,
      edita: false,
    }
  },
  created () {

  },
  computed: {
    ...mapGetters(['permission']),
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    /* 获取当前用户信息 */
    getUserInfo () {
      this.$http.get(this.$setting.userUrl).then(res => {
        if (res.data.code === 0) {
          var info = JSON.parse(JSON.stringify(res.data.data))
          this.userInfo = info
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    /**
     * 显示编辑
     */
    edit (row) {
      const loading = this.$loading({lock: true})
      this.editForm = row
      if (this.editForm) {
        this.showEdit = true
        loading.close()
      }
    },
    // 删除
    remove (row) {
      this.$confirm('确定要删除选中的套餐吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true})
        this.$http.post('/VmPackage/delete', {id: row.id}).then(res => {
          loading.close()
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg})
            this.$refs.table.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {
          loading.close()
          this.$message.error(e.message)
        })
      })
    },
    /**
     * 保存编辑
     */
    save () {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          this.$http.post('/VmPackage/edit', this.editForm).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.showEdit = false;
              this.editForm = res.data.data
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload,
.ele-block >>> .el-upload-dragger {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 30px !important;
}
</style>